import React, {useContext} from 'react'
import {Accordion, AccordionSummary, AccordionDetails, Table, TableHead, TableBody, TableCell, TableRow} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useCourseData } from '../../hooks/useCourseData';

import { AuthContext } from '../../state/context/authContext';

import {Template} from '../layouts/Template';
import EmployeeResult from '../employeeResult';

import { getIdByUrl } from '../../utils/getIdByUrl';

import styles from '../../styles/pages/employee.module.css';

export default function Employee() {
    const authContext = useContext(AuthContext);
    const { authState } = authContext;

    const { loading, isCourses, isError } = useCourseData();

    const employee = authState.emlpoyees.find( employee => employee._id === getIdByUrl() );

    if(loading) return <Template><div>Загрузка...</div></Template>
    if(!isError)  return <Template><div>Произошла непредвиденная ошибка, мы уже работаем над её устранением...</div></Template>

    return (
        <>
            <Template>
                <div className={styles.wrapper}>
                    <h2 style={{marginBottom: '20px'}}>{employee?.full_name}</h2>

                        <div className={styles.accordionContainer}>
                            <Accordion
                                disabled
                                classes={{disabled: styles.accordionDisabled}}
                            >
                                <AccordionSummary
                                    classes={{disabled: styles.accordionDisabled ,
                                            expandIcon: styles.expandIconHide,
                                        }}
                                    expandIcon={<ExpandLessIcon/>}
                                >
                                    <div style={{width:'100%'}}>Курс:</div>
                                    <div style={{width:'100%'}}>Статус:</div>
                                </AccordionSummary>
                            </Accordion>
                        </div>


                    {
                        isCourses
                        .filter( course => (
                            employee?.company_courses?.find( id => course._id  === id ) ||
                            employee?.courses?.find( id => course._id  === id )
                        ))
                        .map( course => {
                            const test = employee?.current_tests?.find(test => course.tests[0] === test._id);
                            return (
                                <div key={course._id} className={styles.accordionContainer}>
                                    <Accordion
                                        disabled={(!test) || (test.status === 'started' && !('passed' in test))}
                                        classes={{disabled: styles.accordionDisabled}}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandLessIcon/>}
                                            classes={{disabled: styles.accordionDisabled}}
                                        >
                                            <div style={{width:'100%'}}>{course.name}</div>
                                            {!test ? <div style={{width:'100%'}}>Не приступал</div> : 
                                              test.status === 'started' && !('passed' in test) ? <div style={{width:'100%'}}>В работе</div> :
                                              <div style={{width:'100%'}}>Смотреть результаты</div>
                                              }
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <EmployeeResult test={test}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ) 
                        } )
                    }
                </div>
            </Template>
        </>
    )
}
