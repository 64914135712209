import React, {useContext} from 'react';
import { Template } from '../layouts/Template';
import { getIdByUrl } from '../../utils/getIdByUrl';
import { useCourseData } from '../../hooks/useCourseData';
import { getObjectById } from '../../utils/getObjectById';
import styles from '../../styles/pages/course.module.css';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from '@material-ui/core';
import { AspectRatio, DateRangeOutlined } from '@material-ui/icons';
import { Link } from "react-router-dom";
import {AuthContext} from '../../state/context/authContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Course = () => { 
    const authContext = useContext(AuthContext);
    const {authState} = authContext;

    const userSettings = authState?.userData?.user_settings;


    const { isCourses, loading} = useCourseData();
    const course = getObjectById({array: isCourses, id: getIdByUrl()});

    const [currentPage, setCurrentPage] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [fullScreenState, setFullScreenState] = useState(false);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    };

    const pageHandlerByKeyBoard = (e) => {
        if(e.keyCode === 38 || e.keyCode === 39) {
            pageHandler('next');
        } else if (e.keyCode === 37 || e.keyCode === 40) {
            pageHandler('prev');
        }
    }

   const fullScreenHandler = () => {
        setFullScreenState(!fullScreenState);
    }

    const pageHandler = (dirrection) => {
        if(dirrection === 'next') {
            const nextPage = currentPage + 1;
            if(nextPage !== numPages + 1) setCurrentPage(nextPage);
        } else {
            const nextPage = currentPage - 1;
            if(nextPage > 0) setCurrentPage(nextPage);
        }
    };

    const test = userSettings.current_tests.find( test => test._id === course?.tests[0] );

    const t = new Date(+(new Date(test?.date_closed)) + +(new Date( (test?.hours_to_try_again || 1) * 60 * 60 * 1000 )));

    if(loading) return <span>loading...</span>
    return (
        <div onKeyDown={(e) => pageHandlerByKeyBoard(e)}>
        {course ? 
        <Template fullScreen={fullScreenState}>
        <div className={styles.container}>

            <div className={styles.info} style={fullScreenState ? {zIndex: 3} : {}}>
                <span className={styles.title}>{course.name}</span>
                <span>Страница: {currentPage} / {numPages}</span>
            </div>

            <div className={`${styles.pdfContainer} ${fullScreenState ? styles.pdfContainerFullScreen :  ''}`}>
                <div className={`${styles.pdfInner} ${styles.ratio}`}>
                    <div className={styles.pdfContent}>
                    <div className={styles.openButton} onClick={() => fullScreenHandler()}><AspectRatio /></div>
                    <Document
                    file={course.presentation ? course.presentation.url : ''}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    <Page scale={1} pageNumber={currentPage} />
                    </Document>
                    </div>
                </div>
            </div>

            <div className={`${styles.buttonContainer} ${fullScreenState ? styles.buttonContainerFullScreen : ''}`}>
            <Button disabled={currentPage === 1 ? true : false} variant="contained" color="default" onClick={() => pageHandler('prev')} >Предыдущая страница</Button>

            

            {numPages === currentPage ?
                <>
                    {test?.passed || (new Date() < t) ? 
                    
                        <Link style={{color:'#fff', textDecoration:'none'}} to={`/results/${course?.tests[0]}`}>
                            <Button variant="contained" style={{background: "#5E6737", color: 'white'}} >
                                Смотреть результаты
                            </Button>
                        </Link>
                        :
                        <Link style={{color:'#fff', textDecoration:'none'}} to={`/test/${course?.tests[0]}`}>
                            <Button variant="contained" style={{background: "#5E6737", color: 'white'}} >
                                Приступить к тесту
                            </Button>
                        </Link>
                    }
                </>
            :
             <Button variant="contained" style={{background: "#5E6737", color: 'white'}} onClick={() => pageHandler('next')} >Следующая страница</Button>
            }
            </div>
        </div>
        </Template>
        :
        null
        }
        </div>
    )
}

export default Course;