import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {Accordion, AccordionSummary, AccordionDetails, Table, TableHead, TableBody, TableCell, TableRow} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';

import { AuthContext } from '../../state/context/authContext';
import { useCourseData } from '../../hooks/useCourseData';

import RemainingTime from '../RemainingTime';

import {Template} from '../layouts/Template';

import styles from '../../styles/pages/companyEmlpoeies.module.css';


export default function CompanyEmploeies() {
    const authContext = useContext(AuthContext);
    const { loading, isCourses, isError } = useCourseData();

    const { authState } = authContext;
    const { emlpoyees } = authState;
    const { courses } = authState.userData.user_settings;

    const checkUserCourseStatus = ( user, course ) => {
        const test = user.current_tests.find(test => course.tests[0] === test._id);
        if( test ){
            const { status, passed, date_closed } = test;
            const timer = new Date() - new Date(date_closed);

            return status === `started` && !('passed' in test) ? `В работе` : 
                   passed ? `Сдал` : 
                   <>Не сдал: следующая попытка через <RemainingTime time={timer}/></>
        };
        return 'Не приступал';
    }

    if(loading) return <Template><div>Загрузка...</div></Template>
    if(!isError)  return <Template><div>Произошла непредвиденная ошибка, мы уже работаем над её устранением...</div></Template>
    return (
        <>
            <Template>
                <div className={styles.accordionWrapper}>
                    <h2 style={{marginBottom: '20px'}}>Мои сотрудники</h2>
                    {isCourses
                        .filter( ( course, i ) => courses.find(id => course._id === id) )
                        .map( course => {
                        return (
                            <div key={course._id} className={styles.accordionContainer}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandLessIcon/>}
                                    >
                                        {course.name}
                                    </AccordionSummary>
        
                                    <AccordionDetails>
                                        <Table>

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>ФИО сотрудника</strong></TableCell>
                                                    <TableCell><strong>Статус курса</strong></TableCell>
                                                    <TableCell>Подробнее о сотруднике</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {emlpoyees.map(user => (
                                                    <TableRow 
                                                        key={user._id}
                                                        classes={{hover: styles.rowHover}}
                                                        hover={true}
                                                    >
                                                        <TableCell>{`${user?.full_name}`}</TableCell>
                                                        <TableCell>{checkUserCourseStatus( user, course )}</TableCell>
                                                        <TableCell><Link to={`/emploee/${user._id}`}><InfoIcon/></Link></TableCell>
                                                    </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                            
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )} )
                    }
                </div>
            </Template>
        </>
    )
}
