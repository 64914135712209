import React from 'react';
import styles from '../../styles/MainComponents/License.module.css';

export default function License() {

    
    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Лицензии</h1>
            <p className={styles.description}> ДПО Учебный центр  работает в рамках закона.<br/> <br/> Все выдаваемые документы проходят проверку в службе безопасности. В случае проверки мы предоставим подтверждающие документы, заверенные печатью учебного центра.</p>
            <div className={styles.docsWrapper}>
                <img className={styles.rectangleTop} src='images/Rectangle4.svg' alt='Rectangle4'/>
                <div>
                    <div className={styles.imgWrapper}>
                        <img src='/images/license1.png' alt='license1'/>
                    </div>
                </div>
                <div>
                    <div className={styles.imgWrapper}>
                        <img src='/images/license1.png' alt='license1'/>
                    </div>
                </div>
                <div>
                    <div className={styles.imgWrapper}>
                        <img src='/images/license1.png' alt='license1'/>
                    </div>
                </div>
                <img className={styles.rectangleBottom} src='images/Rectangle5.svg' alt='Rectangle5'/>
            </div>
        </div>
    )
}
