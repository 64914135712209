import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../state/context/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const {authState} = authContext;

  useEffect(() => {
    if(window.Chatra) window.Chatra('kill');
  }, [])

  return (
    <Route
      exact={rest.exact}
      computedMatch={rest.computedMatch}
      path={rest.path}
      url={rest.url}
      render={() => <Component />}
    >
      {!authState.isAuth && <Redirect to='/'/>}
    </Route>
  );
}
export default PrivateRoute;