import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../../styles/MainComponents/Docs.module.css';

export default function Docs() {
    return (
        <div className={styles.wrapper}>
            <ScrollableAnchor id={'docs'}>
                <h1 className={styles.title}>Документы</h1>
            </ScrollableAnchor>
                <p className={styles.description}>После завершения  обучения в Учебном центре Вам будут выдаваться следующие документы: </p>
                <div className={styles.docsWrapper}>
                    <div>
                        <div className={styles.imgWrapper}>
                            <img src='/images/doc1.png' alt='doc1'/>
                        </div>
                        <p>Свидетельство</p>
                    </div>
                    <div>
                        <div className={styles.imgWrapper}>
                            <img src='/images/doc2.png' alt='doc2'/>
                        </div>
                        <p>удостоверение</p>
                    </div>
                    <div>
                        <div className={styles.imgWrapper}>
                            <img src='/images/doc3.png' alt='doc3'/>
                        </div>
                        <p>протокол о заседании комиссии</p>
                    </div>
                </div>
        </div>
    )
}
