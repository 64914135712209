import { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { Template } from '../layouts/Template';
import { getIdByUrl } from '../../utils/getIdByUrl';
import { getObjectById } from 'src/utils/getObjectById';

import styles from '../../styles/pages/test.module.css';

import useTestData from '../../hooks/useTestData';
import useReply from '../../hooks/useReply';

import Question from '../Question';

import { AuthContext } from '../../state/context/authContext';



const Test = () => {
    const {loading, error} = useTestData(getIdByUrl());
    const Reply = useReply(getIdByUrl())
    const authContext = useContext(AuthContext);
    const { authState } = authContext;

    const [isTest, setIsTest] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [numQuestions, setNumQuestions] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [isSure, setIsSure] = useState(false);

    const [isAllAnswers, setIsAllAnswers] = useState(true);

    useEffect(() => {
        if(authState.userData.user_settings) setIsTest( getObjectById( { array: authState.userData.user_settings.current_tests, id:getIdByUrl() } ) )
    }, [authState]);

    useEffect(() => {
        if(isTest) setNumQuestions(isTest.questions.length)
    }, [isTest]);

    useEffect(() => {
        checkingChange();
    }, [isTest, answers]);

    const questionHandler = ((dirrection) => {
        if(dirrection === 'next') {
            const nextQuestion = currentQuestion + 1;
            if(nextQuestion !== numQuestions + 1) setCurrentQuestion(nextQuestion);
        } else {
            const nextQuestion = currentQuestion - 1;
            if(nextQuestion > 0) setCurrentQuestion(nextQuestion);
        }
    });

    const checkingChange = () => {
        // if( isTest?.questions.length >= answers.length || !!answers.find(obj => obj.answers === '') ) setIsAllAnswers(true);
        // return setIsAllAnswers(false);
    }

    if(loading) return <Template><div>Загрузка...</div></Template>;
    if(error) return <Template><div>Произошла непредвиденная ошибка, мы уже работаем над её устранением...</div></Template>;
    
    if(isTest){
        return (
            <> 
            {!Reply.isSuccess ?
            <Template>
                <div className={styles.wrapper}>
                   <div className={styles.questionContainer}>

                        Вопрос:{currentQuestion}/{numQuestions}
                        <Question question={isTest.questions[currentQuestion - 1]} setAnswers={setAnswers} answers={answers}/>

                   </div>    
                    <div className={styles.buttonsWrapper}>
                        <Button disabled={currentQuestion === 1 ? true : false} variant="contained" color="default" onClick={() => questionHandler('prev')} >Предыдущая страница</Button>
                        
                        {numQuestions === currentQuestion ?
                        <Button disabled={!isAllAnswers} onClick={() => setIsSure(true)} variant="contained" style={{background: "#5E6737", color: 'white'}} >
                            {!isAllAnswers ? 'Пройдите все вопросы' : 'Сдать тест'}
                        </Button>
                        :
                        <Button variant="contained" style={{background: "#5E6737", color: 'white'}} onClick={() => questionHandler('next')} >Следующий вопрос</Button>
                        }

                    </div>
                
                </div>

                <Dialog
                    open={isSure}
                    onClose={() => setIsSure(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Вы уверены, что хотите завершить и сдать тест?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setIsSure(false);
                            setTimeout(() => Reply.handleReply(answers), 300);
                            }} 
                            style={{background: "#5E6737", color: 'white'}}
                        >
                            Продолжить
                        </Button>
                        <Button onClick={() => setIsSure(false)} style={{background: "#5E6737", color: 'white'}} autoFocus>
                            Закрыть окно
                        </Button>
                    </DialogActions>
                </Dialog>
            </Template>
            :
            <Template>
                <div className={styles.wrapper}>
                    Отправляем данные на сервер...
                    {Reply.isError ? 'Произошла непредвиденная ошибака, мы уже работаем над её исправлением' : 
                     Reply.isLoading ? null : <Redirect to={`/results/${getIdByUrl()}`}/>}
                </div>
            </Template>
            }
            </>
        )
    }
    return <Template></Template>
}

export default Test;