import React from 'react';
import styles from '../../styles/MainComponents/Prices2.module.css';

export default function Prices() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.discount} style={{backgroundImage:'url("/images/Rectangle3.svg")'}}>
                    

                    <div className={styles.discountWrapper} >
                        <div className={styles.discountTitle}>
                            <img src='/images/discount.svg' alt="x"/>
                            <p>Пригласи друзей!</p>
                        </div>
                        
                        <p>
                            Скидка 20% на все специальности при обучении от 5 человек.
                        </p>
    
                        <a href='#send'>
                            <div>
                                ПОЛУЧИТЬ СКИДКУ
                            </div>
                        </a>
                    </div>
                    
                </div>


                <div className={styles.professionsWrapper}>

                    <div className={styles.profession}>
                        <img src='/images/builder.svg' alt='money'/>
                        {/* <div>
                            <p className={styles.professionTitle}>Стропальщик</p>
                            <p className={styles.professionPrice}>от 4 000 ₽</p>
                        </div> */}
                    </div>

                    <div className={styles.profession}>
                        <img src='/images/driver.svg' alt='money'/>
                        {/* <div>
                            <p className={styles.professionTitle}>Повар</p>
                            <p className={styles.professionPrice}>от 4 500 ₽</p>
                        </div> */}
                    </div>
                    
                    <div className={styles.profession}>
                        <img src='/images/accountant.svg' alt='money'/>
                        {/* <div>
                            <p className={styles.professionTitle}>Оператор</p>
                            <p className={styles.professionPrice}>от 5 000 ₽</p>
                        </div> */}
                    </div>

                </div>

               
            </div>
        </div>
    )
}
