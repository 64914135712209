const getMain = () => '/';
const getLogin = () => '/login';
const getRegister = () => '/register';
const getPasswordForget = () => '/password-forget';
const getPasswordRestore = () => '/password-restore';

const getAllCourses = () => '/courses';
const getUserCourses = () => '/courses-of-user';
const getUserCertificates = () => '/certificates-of-user';
const getUserSettings = () => '/settings';
const getCourse = () => '/courses/:courseid';
const getTest = () => '/test/:testid';
const getResults = () => '/results/:resultid';
const getCompanyEmploeies = () => '/company-emploeies'
const getEmploee = () => '/emploee/:emploeeid';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getMain,
    getLogin,
    getRegister,
    getPasswordForget,
    getPasswordRestore,

    getResults,
    getTest,
    getCourse,
    getAllCourses,
    getUserCourses,
    getUserCertificates,
    getUserSettings,
    getCompanyEmploeies,
    getEmploee,
}