import React from 'react';
import styles from '../../styles/MainComponents/DistanceLearning.module.css';

export default function DistanceLearning() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
                <img src='images/image1.svg' alt='x'/>
            </div>
            <div className={styles.contentWrapper}>
                <h1 className={styles.title}>Очное и дистанционное обучение профессиям </h1>
                
                <div className={styles.advantages}>
                    <div>
                        <img src='images/available.svg' alt='x'/>
                        <p>Доступно для всех</p>
                    </div>
                    <div>
                        <img src='images/license.svg' alt='x'/>
                        <p>Лицензия</p>
                    </div>
                    <div>
                        <img src='images/fast.svg' alt='x'/>
                        <p>Быстро</p>
                    </div>
                </div>
                <div className={styles.btn}>
                    <a href='#send'>
                        ПОДРОБНЕЕ
                    </a>
                </div>
            </div>
        </div>
    )
}
