import React,{useState, useEffect} from 'react';
import {Link, Button} from '@material-ui/core';

import styles from '../../styles/MainComponents/TrustUs.module.css';
import useInterval from '../../hooks/useInterval';

export default function TrustUs() {
    const [shift, setShift] = useState(0);
    const [childQuntity, setChildQuntity] = useState(0);
    const [points, setPoints] = useState([]);

    useInterval(() => {
        if(shift === points[points.length - 1]) setShift(0);
        else setShift(shift + 100);
    }, 5000)

    useEffect(() => {
        const content = document.querySelector(`.${styles.sliderContent}`)
        setChildQuntity(content.children.length - 1);
    }, [shift]);

    useEffect(() => {
        const prev = [];
        for(let i = 0; i <= childQuntity; i++){
            prev.push(i * 100);
        }
        setPoints(prev);
    }, [childQuntity])

    const handleClick = (event) => {
        const target = +event.target.dataset.point;
        setShift(target);
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.sliderWrapper}>
                <div className={styles.sliderContent} style={{transform:`translateX(-${shift}%)`}}>
                    <img src='images/new.png' alt='class'/>
                    <img src='images/new.png' alt='class'/>
                    <img src='images/new.png' alt='class'/>
                </div>

                <div className={styles.indicatorWrapper}>
                    {points.map(item => {
                        return (
                            <div className={styles.indicator} style={shift === item ? {background:'#5E6737'} : {}} key={item} data-point={item} onClick={handleClick}/>
                        )
                    })}
                </div>
            </div>

            <div className={styles.infoWrapper}>
                <h1>Нам можно доверять!</h1>
                <p>Работаем с ведущими предприятиями газовой, перерабатывающей, строительной, транстпортной, нефтедобывающей, химической, энергетической, лесной промышленности</p>
                <div className={styles.partners}>
                    <div>
                        <img src='images/gazprom.png' alt='gazprom'/>
                    </div>
                    <div>
                        <img src='images/rck.png' alt='rck'/>
                    </div>
                    <div>
                        <img src='images/rosseti.png' alt='rosseti'/>
                    </div>
                    <div>
                        <img src='images/rosneft.png' alt='rosneft'/>
                    </div>
                    <div>
                        <img src='images/novatek.png' alt='novatek'/>
                    </div>
                    <div>
                        <img src='images/sber.png' alt='sber'/>
                    </div>
                    <div>
                        <img src='images/severstal.png' alt='severstal'/>
                    </div>
                    <div>
                        <img src='images/transneft.png' alt='transneft'/>
                    </div>
                </div>

                <Link underline='none' href="#send">
                    <Button classes={{root:styles.infoBtn}}>
                        ПОДРОБНЕЕ
                    </Button>
                </Link>
            </div>

           
                
        </div>
    )
}
