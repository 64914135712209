import React from 'react';
import Link from '@material-ui/core/Link';

import styles from '../../styles/MainComponents/Answers.module.css';

export default function Answers() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <h1 className={styles.title}>Ответы на вопросы</h1>
                <p className={styles.question}>как проходит обучение?</p>
                <p className={styles.question}>А если нужно срочно, какой срок?</p>
                <p className={styles.question}>как документы проходят службу безопасности?</p>
                <h2 className={styles.subTitle}>Все ли официально?</h2>
                <p className={styles.subQuestion}>Наш учебный центр осуществляет свою работу только на основе правового поля, установленного законодательством страны. У центра  есть лицензия на обучающую деятельность. Все документы, которые выдаются нашим ученикам, заносятся в государственный реестр, а также хранятся в нашем архиве. В случае возникновения каких-либо спорных ситуаций надзорные органы и комиссии имеют возможность обратиться к нам и убедиться в том, что человек прошёл обучение по профилю в нашем центре и получил необходимый документ государственного образца.</p>
            </div>
            <div className={styles.content}>
                <img className={styles.imgBackground} src='images/answers.png' alt='answers'/>
                <div className={styles.haveQuestionWrapper}>
                    <div className={styles.haveQuestionTitle}>Есть вопросы?</div>
                    <div className={styles.haveQuestionDescription}>Свяжитесь с нами и  наш менеджер обязательно Вам ответит!</div>
                    <div className={styles.haveQuestionPhoneWrapper}>
                        <img src='images/phoneWhite.svg' alt='phoneIcon'/>
                        <Link href="tel:+79224702259">+7 (922) 470-22-59</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
