import React, {useState, useContext, useEffect, useRef} from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent,TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Link } from "react-router-dom";

import styles from '../styles/CourseCard.module.css';

import { SUCCESS } from '../constants';
import config from '../config/index';
import { useHttp } from '../hooks/useHttp';


import { AuthContext } from '../state/context/authContext';

export default function CourseСard({ bought, data }) {
    const { request } = useHttp();
    const inputFile = useRef(null);

    const authContext = useContext(AuthContext);
    const { authState, dispatch } = authContext;
    const userSettings = authState?.userData?.user_settings;
    
    const [dialog, setDialog] = useState(false);
    const [picture, setPicture] = useState(false);
    const [pictureError, setPictureError] = useState(false);

    const [success, setSuccess] = useState();
    const [successErr, setSuccessErr] = useState();

    const [userDataError, setUserDataError] = useState(false);
    const [dirty, setDirty] = useState(false);

    const checkUserData = () => {
        if(!userSettings.entity){
            if(
            userSettings?.first_name.length &&
            userSettings?.first_name.length &&
            userSettings?.phone.length 
            ) setUserDataError(false);
            else setUserDataError(true);
        }
        else{
            if(
                userSettings?.company_name.length &&
                userSettings?.phone.length 
                ) setUserDataError(false);
                else setUserDataError(true);
        }
    };

    useEffect(() => {
        checkUserData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(inputFile?.current?.files.length){
            const formData = new FormData(e.target);
            formData.append('courseId', data._id);
            formData.append('userId', userSettings._id)
            try{
                let result = await fetch(`${config.apiUrl}/courses/${data._id}/get-course`, {
                    method: "POST",
                    body: formData,
                    credentials: 'include',
                })
                result = await result.json();
                if(result.status === 'success'){
                    const res = await request(`${config.apiUrl}/user-account`, "POST");
                    const {data, status} = res;
                    
                    if(status === 200) {
                        await dispatch({type: SUCCESS, data})
                        setSuccess(true)
                    }
                    else setSuccessErr(true);
                }
                else setSuccessErr(true);
            }
            catch(err){
                setSuccessErr(true);
                console.error(err);
            }
            
            setPicture(false);
            setDialog(false);
        }
        else setPictureError(true);
    }

    const handleSubmitEntity = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append('courseId', data._id);
        formData.append('userId', userSettings._id)
        try{
            let result = await fetch(`${config.apiUrl}/courses/${data._id}/get-course`, {
                method: "POST",
                body: formData,
                credentials: 'include',
            })
            result = await result.json()
            if(result.status === 'success'){
                const res = await request(`${config.apiUrl}/user-account`, "POST");
                const {data, status} = res;
                
                if(status === 200) {
                    await dispatch({type: SUCCESS, data})
                    setSuccess(true)
                }
                else setSuccessErr(true);
            }
            else setSuccessErr(true);
        }
        catch(err){
            setSuccessErr(true);
            console.error(err);
        }
        
        setDialog(false);
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container} style={{ backgroundImage:`url(${data?.preview?.url || 'images/unnamed.jpg'})`}}>
                    <div className={styles.titleWrapper}>
                        <p>{data?.name}</p>
                        <h2>{data?.price}₽</h2>
                        <p>{data?.description}</p>
                    </div>
                </div>
                

                {bought ? (
                    <Link
                        className={styles.link}
                        to={`/courses/${data._id}`}
                        style={{textDecoration:'none'}}
                    >
                        <Button
                            classes={{ root: styles.btn }}
                            type='button'
                        >
                                Приступить
                        </Button> 
                    </Link> 
                    ) 
                    : userSettings.applications.find(course => course.courseId === data._id) ? 
                    (
                    <Button
                        classes={{ root: styles.btn }}
                        type='button'
                        disabled
                    >
                        {userSettings.applications.find(course => course.courseId === data._id).status}
                    </Button> 
                    ) :                    
                    ( 
                    <Button
                        classes={{ root: styles.btn }}
                        type='button'
                        onClick={() => {
                            setDirty(true);
                            setDialog(true)
                        }}
                    >
                        Отправить заявку
                    </Button> 
                )}
            </div>

    
    
            <Dialog
                maxWidth={false}
                open={dialog && !userDataError}
                onClose={() => setDialog(false)}
            >
                <DialogTitle>
                    Оформление заявки на курс
                </DialogTitle>
                <form
                    onSubmit={userSettings.entity ? handleSubmitEntity : handleSubmit}
                >
                    <DialogContent
                        classes={{root: styles.dialogContent}}
                        dividers={true}
                    >

                        <div className={styles.dialogColumn}>
                            <p>Курс:</p>
                            <p>{data?.name}</p> 
                            {userSettings?.entity ?
                                <>
                                <p>Компания:</p>
                                <p>{userSettings?.company_name}</p>
                                </>
                                :
                                <>
                                    <p>ФИО:</p>
                                    <p>{userSettings?.last_name} {userSettings?.first_name} {userSettings?.third_name}</p>
                                </>
                            }
                            <p>Телефон:</p>
                            <p>{userSettings?.phone}</p>
                            <label htmlFor='note'>Комментарий</label>
                            <TextField name='note'/>
                        </div>

                        {userSettings.entity ? 
                        null 
                        : <div className={styles.dialogColumn2}>
                            <div className={styles.qrCodeWrapper}>
                                <p>
                                    Для оплаты курса, отсканируйте данный QR код,
                                    оплатите курс, затем прикрепите чек и отправьте заявку.` 
                                </p>
                                <div className={styles.qrCodeImg}>
                                    <img src={data?.qr?.url} alt='QR код оплаты отсутвует. Обратитесь к администрации для решения этой проблемы.'/><br/>
                                    QR код для оплаты
                                </div>
                            </div>

                            <div>
                                {inputFile?.current?.files[0]?.name}
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={inputFile?.current?.files.length ? {backgroundColor: '#2D8226', marginLeft: '10px'} : {}}
                                    >
                                    <input
                                        onChange={() => setPicture(!picture)}
                                        name='image'
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        ref={inputFile}
                                        hidden
                                    />
                                    {inputFile?.current?.files.length ? 'Чек загружен' : 'Загрузите чек для оплаты'}
                                    {inputFile?.current?.files.length ? <CheckCircleIcon/> : null}
                                </Button>
                            </div> 
                            </div>
                        }

                        

    
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type='submit'
                        >
                            Отправить
                        </Button>
                        <Button
                            onClick={() => setDialog(false)}
                        >
                            Закрыть окно
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog maxWidth={false} open={userDataError && dirty} onClose={() => setDirty(false)}>
                <DialogTitle>Для отправки заявки заполните пользовательские данные!</DialogTitle>
                <DialogActions>
                    <Link to='/settings'>
                        <Button>Настройки пользователя</Button>
                    </Link>
                </DialogActions>
            </Dialog>

            <Dialog open={success} onClose={() => setSuccess(false)}>
                <DialogTitle>Заявка успешно отправлена!</DialogTitle>
            </Dialog>

            <Dialog open={successErr} onClose={() => setSuccessErr(false)}>
                <DialogTitle>Ой! Произошла какая-то ошибка. Мы уже работаем над её исправлением!</DialogTitle>
            </Dialog>

            <Dialog open={pictureError} onClose={() => setPictureError(false)}>
                <DialogTitle>Загрузите чек перед отправкой заявки!</DialogTitle>
            </Dialog>
        </>
    )
}