import React from 'react';


import styles from '../../styles/MainComponents/Prices.module.css';

export default function Prices() {
    return (
        <div className={styles.wrapper}>

           
                <h1 className={styles.title}>Цены</h1>

            
                <div className={styles.contentWrapper}>
    
                    <div className={styles.discount} style={{backgroundImage:'url("/images/Rectangle2.svg")'}}>
    
                        <div className={styles.discountTitle}>
                            <img src='/images/discount.svg' alt="x"/>
                            <p>Нашли дешевле?</p>
                        </div>
                        
                        <p>
                            Оставьте заявку, и мы сделаем на 10% дешевле, чем у конкурента
                        </p>
    
                        <a href='#send'>
                            <div>
                                ПОЛУЧИТЬ СКИДКУ
                            </div>
                        </a>
                        
                    </div>
    
                    <div className={styles.professionsWrapper}>

                    <div className={styles.profession}>
                            <img src='/images/lead.svg' alt='money'/>
                        </div>
    
                        <div className={styles.profession}>
                            <img src='/images/operator2.svg' alt='money'/>
                        </div>
                        
                        <div className={styles.profession}>
                            <img src='/images/deliverer.svg' alt='money'/>
                        </div>

                        <div className={styles.profession}>
                            <img src='/images/worker.svg' alt='money'/>
                        </div>
    
                    </div>

                </div>
        </div>
    )
}
