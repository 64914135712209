import React, {useEffect, useState} from 'react';

import styles from '../styles/Question.module.css';

import RadioComponent from './testComponents/RadioComponent';
import CheckboxComponent from './testComponents/CheckboxComponent';
import FieldComponent from './testComponents/FieldComponent';

import { 
    FormLabel, 
    FormControl
} from '@material-ui/core';


function Description({content}) {
    return <div dangerouslySetInnerHTML={{__html: content}} />;
}

export default function Question({ question, setAnswers }) {
    const {type} = question;
    const [variants, setVarianst] = useState([]);

    useEffect(() => {
        setAnswers(variants);
    }, [variants])

    return (
        <>
            <div className={styles.titleWrapper}>
                <h2>{question.name}</h2>
                <p><Description content={question.description} focused/></p>
            </div>

            <div className={styles.answersWrapper}>

                    <form
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <FormControl style={{paddingBottom:'50px'}} component="fieldset">
                            
                            <FormLabel style={{marginBottom:'20px'}} component="legend">Укажите правильный ответ</FormLabel>
                            {type === 'checkbox' ? (
                                <CheckboxComponent question={question} setVarianst={setVarianst} variants={variants}/>
                            ) : type === 'radio' ? (
                                <RadioComponent question={question} setVarianst={setVarianst} variants={variants}/>

                            ) : <FieldComponent question={question} type={type} setVarianst={setVarianst} variants={variants}/>}
                            
                        </FormControl>
                        
                    </form>

                        

    

            </div>
        </>
    )
}
