import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom'

import styles from '../../../styles/login/login.module.css';

import NavBar from '../../NavBar';

import {useAuth} from '../../../hooks/useAuth';

import {AuthContext} from '../../../state/context/authContext';
import {Button, Dialog, DialogTitle} from '@material-ui/core';




let ViewShowPass = () => {
    let inputId = document.getElementById('passwordInput');
    let imgId = document.getElementById('passwordImg');

    if (inputId.getAttribute('type') === 'password') {
        inputId.setAttribute('type', 'text');
        imgId.src = './no-view.svg';
        imgId.title = "Скрыть пароль";
    } else {
        inputId.setAttribute('type', 'password');
        imgId.src = './view.svg';
        imgId.title = "Показать пароль";
    }
    return false;
}



export default function Login() {
    const authContext = useContext(AuthContext);
    const {authState} = authContext;

    const {loginHandler, isAuthError} = useAuth();

    const [popap, setPopap] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();

        const form = document.forms[0];
        const data = {
            email: new FormData(form).get('email'),
            password: new FormData(form).get('password'),
        };

        await loginHandler(data);
    };

    useEffect(() => {
        if (isAuthError === false) setRedirect(true);
        if (isAuthError === true) setPopap(true);
    }, [isAuthError, authState])

    if (redirect) {
        return <Redirect to="/courses"/>;
    }
    return (
        <>
            <NavBar/>

            <div className={styles.login}>

                <form onSubmit={handleSubmit}>
                    <div className={styles.form}>
                        <h1 className={styles.title}>Личный кабинет</h1>
                        <input name="email" className={styles.input} placeholder="Электронная почта"/>

                        <div className={styles.passContainer}>
                            <input name="password" type="password" id="passwordInput" className={styles.input}
                                   placeholder="Пароль"/>
                            <img src="./view.svg" title="Показать пароль" className={styles.viewPas} id="passwordImg" onClick={ViewShowPass}/>
                        </div>

                        <Link to="/password-forget">
                            <p className={styles.text}>Я не помню пароль</p>
                        </Link>
                        <Button type="submit" onClick={handleSubmit} classes={{root: styles.send}}>
                            Войти
                        </Button>
                    </div>
                    <div className={styles.edit}>
                        <p className={styles.text}>У меня еще нет учетной записи</p>

                        <p className={styles.text}><Link to="/register">зарегистрироваться</Link></p>

                    </div>
                </form>

                <Dialog
                    onClose={() => {
                        setPopap(false)
                    }}
                    open={popap}
                >
                    <DialogTitle>
                        {authState.message}
                    </DialogTitle>
                </Dialog>

            </div>
        </>
    );
}
