import React, {useEffect, useState} from 'react';


import { 
    Checkbox,
    FormControlLabel, 
} from '@material-ui/core';

const CheckboxComponent = ({ question, setVarianst, variants }) => {
    const [value, setValue] = useState([]);

    const handleChange = (e) => {
        if(!e.target.checked){
            const prevValue = [...value].filter(obj => obj.answers !== e.target.value);
            setValue(prevValue);
        }
        else{
            setValue([...value, {id: question._id, answers: e.target.value}]);
        }
    };
    
    useEffect(() => {
        const newVariants = [...variants].filter(obj => obj.id !== question._id);
        setVarianst([...newVariants, ...value])
    }, [value]);

    useEffect(() => {
        const prevAnswer = variants.filter(obj => obj.id === question._id);
        if(prevAnswer){
            setValue(prevAnswer);
        }
        else {
            setValue([]);
        }
    }, [question]);

    return (
        <>
            {question.answer_options.map(answer => (
                <FormControlLabel
                    key={answer._id}
                    value="top"
                    control={<Checkbox 
                        onChange={handleChange}
                        value={answer.name}
                        checked={!!value.find(obj => obj.answers === answer.name)}
                        color="primary" 
                        />}
                    label={answer.name}
                    labelPlacement="end"
              />
            ))}
        </>
    )
};

export default CheckboxComponent;