import React, { useState, useContext, useEffect } from 'react';

import styles from '../../../styles/login/login.module.css';

import { useHttp } from '../../../hooks/useHttp';

import NavBar from '../../NavBar';


export default function PasswordRestore() {

  const { request } = useHttp();

  const [result, setResult] = useState(0);

  // const confirmedRegistration = async () => {
  //   const req = await request(`${config.serverAJAXurl}/register`, `POST`, {
  //     token: router.query.token,
  //   });
  //   if (req.data.isCustomerSaved) {
  //     setResult(3);
  //     setTimeout(() => {
  //       setResult(0);
  //     }, 3000);
  //   } else {
  //     setResult(4);
  //     setTimeout(() => {
  //       setResult(0);
  //     }, 3000);
  //   }
  // };

  // useEffect(async () => {
  //   if (router.query.token) await confirmedRegistration();
  // }, [router]);


  // useEffect(() => {
  //   if (result === 1) {
  //     router.push('login/account');
  //   }
  // }, [result]);

  const handleSubmit = async event => {
    event.preventDefault();
    // const form = document.forms[0];
    // const data = {
    //   password: new FormData(form).get('password'),
    // };
    // if (new FormData(form).get('password') !==  new FormData(form).get('password2')){
    //   setResult(2);
    //   setTimeout(() => {
    //     setResult(0);
    //   }, 3000);
    // }
    // else{
      // const response = await state.loginUser(data);
      // if (await response.authenticated) {
      //   state.setUserData(response);
      //   setResult(1);
      // } else {
      //   setResult(2);
      //   setTimeout(() => {
      //     setResult(0);
      //   }, 3000);
      // }
    }
    return (
      <>
       <NavBar />
        <div className={styles.login}>
          <div
            className={styles.requestMessage}
            style={
              result === 2 || result === 3 || result === 4
                ? { transform: 'scale(1) translate(-50%, -50%)' }
                : { transform: 'scale(0) translate(-50%, -50%)' }
            }>
            {/* eslint-disable-next-line no-nested-ternary */}
            {result === 2
              ? 'Пароли не совпадают!'
              : result === 3
              ? 'Почта успешно подтвержена'
              : 'Что то произошло не так :('}
          </div>
    
          <form onSubmit={handleSubmit}>
            <div className={styles.form}>
              <h1 className={styles.title}>Восстановление<br/> пароля</h1>
              <input name="password" type="password" className={styles.input} placeholder="Пароль" />
              <input name="password2" type="password" className={styles.input} placeholder="Повторите пароль" />
              <button type="submit" onClick={handleSubmit} className={styles.send}>
                Восстановить
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

