import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom'

import styles from '../../../styles/login/login.module.css';

import { useHttp } from '../../../hooks/useHttp';

import NavBar from '../../NavBar';


export default function PasswordForget() {
  const [result, setResult] = useState(0);

  // useEffect(() => {
  //   if (result === 1) {
  //     router.push('/login/password-restore');
  //   }
  // }, [result]);

  const handleSubmit = async event => {
    event.preventDefault();

    // const form = document.forms[1];
    // const data = {
    //   email: new FormData(form).get('email'),
    // };

    // setResult(2);
    // setTimeout(() => {
    //   setResult(1);
    // }, 4000);

    // const response = await state.loginUser(data);
    // if (await response.authenticated) {
    //   state.setUserData(response);
    //   setResult(1);
    // } else {
    //   setResult(2);
    //   setTimeout(() => {
    //     setResult(0);
    //   }, 3000);
    // }
  };

  return (
   <>
   <NavBar />
      <div className={styles.login}>
  
        <div
          className={styles.requestMessage}
          style={
            result === 2 || result === 3 || result === 4
              ? { transform: 'scale(1) translate(-50%, -50%)' }
              : { transform: 'scale(0) translate(-50%, -50%)' }
          }>
          {/* eslint-disable-next-line no-nested-ternary */}
          {result === 2
            ? 'Мы выслали письмо вам на почту, проверьте, ваш почтовый ящик'
            : 'Что то произошло не так :('}
        </div>
  
        
  
        <form onSubmit={handleSubmit}>
          <div className={styles.form}>
            <h1 className={styles.title}>Забыли пароль?</h1>
            <input name="email" className={styles.input} placeholder="Электронная почта" />
            <button type="submit" onClick={handleSubmit} className={styles.send}>
              Восстановить
            </button>
          </div>
          
          <div className={styles.edit}>
            <p className={styles.text}>У меня еще нет учетной записи</p>
            <Link to="/register">
              <p className={styles.text}>зарегистрироваться</p>
            </Link>
          </div>
        </form>
  
      </div>

   </>
  );

}
