import React, {useState, useContext, useEffect} from 'react'
import styles from '../styles/NavBar.module.css'
import { Link } from "react-router-dom";

import { AuthContext } from '../state/context/authContext';

export default function NavBar() {
    const [opened, setOpened] = useState(false);
    const authContext = useContext(AuthContext);
    const {authState} = authContext; 

    useEffect(() => {
        const mobileWrapper = document.querySelector(`.${styles.mobileWrapper}`);
        if(opened){
            mobileWrapper.style.display = 'flex';
        }
        else setTimeout(() => mobileWrapper.style.display = 'none', 300);
    },
    [opened]);

    return (
        <div className={styles.sticky} >
            <div className={styles.wrapper}>
                <a href='/#begin'>С чего начать</a>
                <a href='/#prices'>Цены и профессии</a>
                <a href='/#docs'>Документы</a>
                <a href='/#contacts'>Контакты</a>
                
                <Link className={styles.login} to={authState.isAuth ? '/courses' : '/login'}>
                    <img src='images/autorisation.svg' alt='login'/>
                    {authState.isAuth ? 'В личный кабинет' : 'Авторизоваться'}
                </Link>
                
                <div className={styles.logo}>ЛОГОТИП</div>
                <button type='button' className={styles.humburger} onClick={() => {setOpened(!opened)}}>
                    <img src='images/hamburger.png' alt='='/>
                </button>
            </div>

            <div className={styles.mobileWrapper} style={opened ? {opacity:'1', zIndex:'999'} : {opacity:'0', zIndex:'-999'}}>
                <Link onClick={() => {setOpened(false)}} to={authState.isAuth ? '/courses' : '/login'}>
                {authState.isAuth ? 'В личный кабинет' : 'Авторизоваться'}
                </Link>
                <a onClick={() => {setOpened(false)}} href='/#begin'>С чего начать</a>

                <a onClick={() => {setOpened(false)}} href='/#prices'>Цены и профессии</a>

                <a onClick={() => {setOpened(false)}} href='/#docs'>Документы</a>
                <a onClick={() => {setOpened(false)}} href='/#contacts'>контакты</a>
            </div>
        </div>
    )

}
