import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Formik, Form, Field } from 'formik';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';

import styles from '../../styles/MainComponents/Consultation.module.css';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

export default function Consultation() {


    const phoneRegExp = /^(\+7|[78])(\s?|\-?)(\(\d{3}\)|\d{3})(\-?|\s?)\d{3}(\-?|\s?)\d{2}(\-?|\s?)\d{2}$/;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
        phone: Yup.string().matches(phoneRegExp, 'Некорректный номер телефона').required('Обязательное поле'),
        checkbox: Yup.string().required('Установите галочку'),
    });

    const initialValues = {
        name: '',
        phone: '',
        note: '',
        checkbox: '',
    }

    return (
        <div className={styles.wrapper}>
            <ScrollableAnchor id={'send'}>
                <h1>Для более подробной информации свяжитесь с нами</h1>
            </ScrollableAnchor>

                <div className={styles.contantWrapper}>
                    <div className={styles.contant}>
                        <h2>Заказать консультацию</h2>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({handleChange}) => (
                            <Form>
                                <div>
                                    <Field className={styles.inputText} name='name' placeholder='Имя'/>
                                </div>
                                <div>
                                    <Field  
                                        name='phone' 
                                        render={
                                            (({field}) => (
                                                <MaskedInput
                                                    {...field}
                                                    guide={true}
                                                    placeholder='+7'
                                                    className={styles.inputText}
                                                    mask={['+', '7', '(', /\d/, /\d/, /\d/, ')',  /\d/,  /\d/,  /\d/, '-',  /\d/, /\d/, '-',  /\d/, /\d/,]}
                                                />
                                            ))
                                        }
                                    />
                                </div>
                                <div>
                                    <Field as='textarea' className={styles.inputTextarea} name='note' placeholder='Комментарий...'/>
                                </div>
                                <div>
                                    <FormControlLabel
                                        onChange={handleChange}
                                        label='Я принимаю условия соглашения.'
                                        control={
                                            <Checkbox classes={{checked: styles.inputCheckBox}} name='checkbox'/>
                                        }
                                    />

                                    <label className={styles.labelCheckBox}></label>
                                </div>
                                <div>
                                    <Button classes={{root: styles.submitBtn}} type='submit'>ОТПРАВИТЬ</Button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                    </div>
                    <div className={styles.imgWrapper}>
                        <img src='images/Rectangle1.svg' alt='x'/>
                    </div>
                </div>
        </div>
    )
}
