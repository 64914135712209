import ScrollableAnchor from 'react-scrollable-anchor';
import React from 'react';
import Link from '@material-ui/core/Link';

import styles from '../../styles/MainComponents/Footer.module.css';



export default function Footer() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <ScrollableAnchor id={'contacts'}>
                    <div/>
                </ScrollableAnchor>
                <div className={styles.requisitesWrapper}> 
                    <h2>Реквизиты: </h2>
                    <h2>Общество с ограниченной ответственностью «ПроДвижение»</h2>
                    <p>ЗАПАДНО-СИБИРСКОЕ<br/> ОТДЕЛЕНИЕ№8647 ПАО СБЕРБАНК</p>
                    <p>Р/счет: 40702810967100024506</p>
                    <p>К/счет: </p>
                    <p>БИК: 047102651</p>
                    <p>ОГРН: 1207200017210</p>
                    <p>ИНН/КПП: 7203510708/720301001</p>
                </div>

                <div className={styles.infoWrapper}> 
                    <h2>Учебный центр -<span> дополнительное профессиональное образование, переподготовка, повышение квалификации работников</span></h2>
                    <p>Работаем по Тюмени и Тюменской области</p>
                </div>

                <div className={styles.contactsWrapper}> 
                    <h2>Связь с нами:</h2>
                    <p>Россия, Тюменская область,<br/> г. Тюмень, ул. 50 лет октября, д.57б, кв.171</p>
                    <Link href="tel:+79224702259">Тел: +7 (922) 470-22-59</Link>
                    <Link href="mailto:ProDvigenie@yandex.ru">Email: ProDvigenie@yandex.ru</Link>
                    <a href="vk.com"><img src='images/vk.svg' alt="vkIcon"/></a>
                </div>
                
            </div>

            <p className={styles.year}>ООО «ПроДвижение» © 2021</p>
        </div>
    )
}
