import React, {useContext} from 'react';
import { Switch } from 'react-router';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import routes from '../constants/routes'; 

import Landing from '../components/screens/landing';
import Login from '../components/screens/login';
import PasswordForget from '../components/screens/login/password-forget';
import PasswordRestore from '../components/screens/login/password-restore';
import Register from '../components/screens/login/register';

import Course from '../components/screens/course';
import Test from '../components/screens/test';
import AllCourses from '../components/screens/allCourses';
import UserCourses from '../components/screens/userCourses';
import UserCertificates from '../components/screens/userCertificates';
import UserSetting from '../components/screens/userSetting';
import Results from '../components/screens/results';
import CompanyEmploeies from '../components/screens/companyEmploeies';
import Employee from '../components/screens/employee';

import { AuthContext } from '../state/context/authContext';

const AppRoutes = () => {
    const authContext = useContext(AuthContext);
    const {authState} = authContext; 

    if(!authState.loading){
        return (
            <Router>
                <Switch>
                    <PublicRouter exact path={routes.getMain()} component={Landing} />
                    
                    
                        
                            <PublicRouter exact path={routes.getLogin()} component={Login} />
                            <PublicRouter exact path={routes.getRegister()} component={Register} />
                            <PublicRouter exact path={routes.getPasswordForget()} component={PasswordForget} />
                            <PublicRouter exact path={routes.getPasswordRestore()} component={PasswordRestore} />
                        
                    
                        
                            <PrivateRouter exact path={routes.getUserSettings()} component={UserSetting} />
                            <PrivateRouter exact path={routes.getUserCourses()} component={UserCourses} />
                            <PrivateRouter exact path={routes.getUserCertificates()} component={UserCertificates} />
                            <PrivateRouter exact path={routes.getAllCourses()} component={AllCourses} />
                            <PrivateRouter exact path={routes.getCourse()} component={Course} />
                            <PrivateRouter exact path={routes.getTest()} component={Test} />
                            <PrivateRouter exact path={routes.getResults()} component={Results} />
                            <PrivateRouter exact path={routes.getCompanyEmploeies()} component={CompanyEmploeies} />
                            <PrivateRouter exact path={routes.getEmploee()} component={Employee} />
    
                    
                </Switch>
            </Router>
        )
    }
    return <div/>
}

export default AppRoutes