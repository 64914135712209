import React from 'react';

import styles from '../../styles/landing.module.css'

import NavBar from '../NavBar';
import Training from '../MainComponents/Training'
import DistanceLearning from '../MainComponents/DistanceLearning'
import WhereToBegin from '../MainComponents/WhereToBegin';
import Consultation from '../MainComponents/Consultation';
import Prices from '../MainComponents/Prices';
import Prices2 from '../MainComponents/Prices2';
import TrustUs from '../MainComponents/TrustUs';
import AboutProfessions from '../MainComponents/AboutProfessions';
import Docs from '../MainComponents/Docs';
import License from '../MainComponents/License';
import Answers from '../MainComponents/Answers';
import Footer from '../MainComponents/Footer';
import {configureAnchors} from 'react-scrollable-anchor'
import ReadingCourses from "../readingCourses/ReadingCourses";


function Landing() {
    configureAnchors({offset: -100})
    return (
        <div className={styles.app}>
            <NavBar/>
            <div className={styles.logo}><img src='/images/logo.jpg' alt='logo'/></div>
            <Training/>
            <DistanceLearning/>
            <WhereToBegin/>
            <AboutProfessions/>
            <Consultation/>
            <Prices/>
            <Prices2/>
            <TrustUs/>
            <Docs/>
            <License/>
            <Answers/>
            <Footer/>
        </div>
    );
}

export default Landing;
