import { useContext, useState } from 'react';
import { AuthContext } from '../state/context/authContext';
import { GET_TEST } from '../constants';

import {useHttp} from './useHttp';

import config from '../config/index';

export default function useReply(id) {
    const { request } = useHttp();
    const authContext = useContext(AuthContext);
    const { dispatch } = authContext;
    const [isSuccess, setIsSuccess] = useState(false)

    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);


    const handleReply = async (data) => {
        setLoading(true)

        let reduceData = [...data]
        
        reduceData = reduceData.sort((a,b) => {
            if( a > b ) return 1;
            if( a === b ) return 0;
            if( a < b ) return -1;
        })
        .map(item => {
            item.answers = [item.answers];
            return item;
         })
        .reduce((acc,current, i, array) => {
            const next = array[i + 1];
            if(next && next.id === current.id){
                next.answers = [...current.answers, ...next.answers];
            }
            else acc.push(current);
            return acc;
        }, [])

        const reqData = {answers: reduceData};

        const result = await request(`${config.apiUrl}/tests/${id}/reply`, 'POST', reqData);

        if(result.ok){
            await dispatch({type: GET_TEST, userSettings: result.data});
            setIsSuccess(true);
            setLoading(false);
        }
        else{
            setError(true)
        }

    }

    return {isLoading, isError, handleReply, isSuccess}
}
