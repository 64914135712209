import React, { useEffect } from 'react';

import AppRoutes from './routes';

function App() {
  useEffect(() => {
    (function(d, w, c) {
      w.ChatraID = 'ZPArNwQmu8zmRxq7b';
      var s = d.createElement('script');
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');
  window.ChatraSetup = {
    colors:{
       buttonText: '#fff',
       buttonBg:'#5E6737',
    }
  }
  }, []);

  return (
    <AppRoutes />
  );
}

export default App;

