import React, { useContext } from 'react';
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask'
import { Link } from "react-router-dom";
import {Template} from '../layouts/Template';

import styles from '../../styles/userSettings.module.css';

import {useUserChange} from '../../hooks/useUserChange';

import {AuthContext} from '../../state/context/authContext';
import { Dialog, DialogTitle } from '@material-ui/core';

export default function UserSettings() {
    const authContext = useContext(AuthContext);
    const {authState} = authContext;

    const userSettings = authState?.userData?.user_settings;

    const initValue = {
        email: userSettings?.email,
        first_name: userSettings?.first_name,
        third_name: userSettings?.third_name,
        last_name: userSettings?.last_name,
        company_name: userSettings?.company_name,
        phone: userSettings?.phone,
    };

    const { handleSubmit, isSuccess, isError, setSuccess,  setError, } = useUserChange();

    const phoneRegExp = /^(\+7|[78])(\s?|\-?)(\(\d{3}\)|\d{3})(\-?|\s?)\d{3}(\-?|\s?)\d{2}(\-?|\s?)\d{2}$/;

    const validationSchema = Yup.object().shape({
        password: Yup.string().min(8, "Пароль должен состоять минимум из 8 символов"),
        repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли не совпадают!'),
        first_name: Yup.string().matches(/\D/g, {message:'Введите имя'}),
        third_name: Yup.string().matches(/\D/g, {message:'Введите отчество'}),
        last_name: Yup.string().matches(/\D/g, {message:'Введите фамилию'}),
        phone: Yup.string().matches(phoneRegExp, 'Некорректный номер телефона'),
    });
    
    return (
        <>
            <Template>
                <div className={styles.wrapper}>
                
                <h1>Данные пользователя</h1>
                    <Formik
                        initialValues={initValue}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};
                            if(values.password && !('repeatPassword' in values)) errors.repeatPassword = 'Подтвердите пароль!';
                            return errors;
                        }}
                        onSubmit={async (v, api) => {
                            Object.entries(v).forEach(n => n[1] === '' && delete v[n[0]])
                            if('repeatPassword' in v) delete v.repeatPassword;
                            if('email' in v) delete v.email;
                            console.log(v)
                            await handleSubmit(v);
                            api.setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, dirty, errors, handleChange}) => {
                        return (
                            <Form className={styles.form}>
                                <div className={styles.formColumn}>
                                    
                                    {!userSettings.entity ? <>
                                        <label htmlFor='last_name'>Фамилия:
                                        {errors.last_name ? <div className={styles.validateError}>{errors?.last_name}</div> : null}
                                        </label>
                                        <Field name='last_name' />
    
                                        <label htmlFor='first_name'>Имя:
                                        {errors.first_name ? <div className={styles.validateError}>{errors?.first_name}</div> : null}
                                        </label>
                                        <Field name='first_name' />
    
                                        <label htmlFor='third_name'>Отчество:
                                        {errors.third_name ? <div className={styles.validateError}>{errors?.third_name}</div> : null}
                                        </label>
                                        <Field name='third_name' placeholder='Необязательно'/>
                                    </> : null}

                                    <label htmlFor='phone'>Номер телефона:
                                        {errors.phone ? <div className={styles.validateError}>{errors?.phone}</div> : null}
                                    </label>
                                     

                                    <Field className={styles.phone} name='phone'
                                        render={ ({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                guide={false}
                                                mask={[ '+', '7','(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/,'-', /\d/, /\d/]}
                                                placeholder="+7"
                                                onChange={handleChange}
                                            />
                                        ) }
                                    />

                                    <label htmlFor='email'>Email:

                                    </label>
                                    <Field disabled name='email'/>

                                    {userSettings.company_id || userSettings.entity ? 
                                        <>
                                            <label htmlFor='company_name'>Компания:</label>
                                            <Field disabled name='company_name'/> 
                                        </>
                                    : null}




                                </div>
                                <div className={styles.formColumn}>
                                    <h3 className={styles.passwordTitle}>Сменить пароль:</h3>
                                    <label htmlFor='password'>Пароль:
                                    {errors.password ? <div className={styles.validateError}>{errors?.password}</div> : null}
                                    </label>
                                    <Field type="password" name='password'/>

                                    <label htmlFor='repeatPassword'>Повторите пароль:
                                    {errors.repeatPassword ? <div className={styles.validateError}>{errors?.repeatPassword}</div> : null}
                                    </label>
                                    <Field type="password" name='repeatPassword'/>
                                </div>
                                <Button 
                                    type='submit' 
                                    classes={{
                                        root: styles.submitBtn,
                                        disabled: styles.disabledSubmitBtn,
                                    }}
                                    disabled={isSubmitting || !dirty}
                                >
                                    {!dirty ? 'Измените данные' : isSubmitting ? "Отправка..." : "Сохранить"}
                                </Button>

                                
                                <Link style={{textDecoration:'none'}} to='/courses'>
                                    <Button 
                                        type='button' 
                                        classes={{
                                            root: styles.submitBtn,
                                            disabled: styles.disabledSubmitBtn,
                                        }}
                                    >
                                        Перейти к отправке заявки
                                    </Button>
                                </Link>

                            </Form>    
                        )}}
                    </Formik>




                    <Dialog
                        onClose={() => setSuccess(false)}
                        open={isSuccess}
                    >
                        <DialogTitle>
                            Данные успешно изменены!
                        </DialogTitle>
                    </Dialog>

                    <Dialog
                        onClose={() => setError(false)}
                        open={isError}
                    >
                        <DialogTitle>
                            Произошла ошибка, попробуйте позже!
                        </DialogTitle>
                    </Dialog>

                </div>
            </Template>
        </>
    )
}