import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../state/context/authContext';
import { SUCCESS, SUCCESS_REGISTER, ERROR,EMLPOYEES_SUCCES, EMLPOYEES_ERROR } from '../constants';
import { cookiesSet } from '../state/cookies';

import {useHttp} from '../hooks/useHttp';

import config from '../config/index';
import { failedLogin, failedRegistration } from 'src/utils/messages';

export const useAuth = () => {
  const {request} = useHttp();

  const authContext = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [isRegistered, setRegistered] = useState(false);
  const [isAuthError, setError] = useState();
  const [isReset, setReset] = useState(false);
  const { dispatch } = authContext;
  
  const loginUrl = `${config.apiUrl}/login`;
  const registerUrl = `${config.apiUrl}/register`;

  const authHandler = async (input = {}, handler = '') => {
    const { url, body } = input;
    setLoading(true);
    setReset(false);
   
    
    const res = await request(url, "POST", body);
    const { status, data } = res;

    if(status === 200){
      if (handler === 'login') {
        dispatch({ type: SUCCESS, data });
        

        if(data?.user_settings?.entity) {
          const result = await request(`${config.apiUrl}/employees`, 'POST');
          const { data, ok } = result;
          dispatch(ok ? {type: EMLPOYEES_SUCCES, data: data.data} : {type: EMLPOYEES_ERROR})
        }
      }
      if (handler === 'register') {
        dispatch({ type: SUCCESS_REGISTER });
        setRegistered(true)
      }
      setError(false);
    }
    else{
      if (handler === 'login') {
        dispatch({ type: ERROR, message:failedLogin() });
      }
      if (handler === 'register') {
        dispatch({ type: ERROR, message:failedRegistration() });
      }
      setError(true);
    } 


    return setLoading(false);
  };


  const loginHandler = ({email, password} = null) => authHandler({ url: loginUrl, body: {email, password } }, 'login');

  const registerHandler = ({email, password}) => authHandler({ url: registerUrl,  body: {email, password } }, 'register');

  // const resetHandler = () => authHandler({ url: resetUrl }, 'reset');

  return {
    isLoading,
    loginHandler,
    registerHandler,
    // resetHandler,
    isAuthError,
    setError,
    isRegistered,
    isReset,
  };
};