import {Button} from '@material-ui/core';
import React, {useState} from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../../styles/MainComponents/AboutProfessions.module.css';
import ReadingCourses from "../readingCourses/ReadingCourses";


const contents = [
    {
        id: 0,
        price: `От 5000р`,
        title: `Водитель погрузчика 2 разряда -`,
        text: ` Управление тракторными погрузчиками и разгрузчиками, 
                вагонопогрузчиками, вагоноразгрузчиками и всеми специальными грузозахватными механизмами
                и приспособлениями при погрузке, выгрузке, перемещении и укладке в штабель различных 
                грузов под руководством водителя более высокой квалификации. Участие в планово-предупредительном 
                ремонте погрузо-разгрузочных и грузозахватных механизмов и приспособлений.`
    },
    {
        id: 1,
        price: `От 5000р`,
        title: `Водитель погрузичка 3 разряда -`,
        text: ` Управление аккумуляторными погрузчиками и всеми специальными грузозахватными 
        механизмами и приспособлениями при погрузке, выгрузке, перемещении и укладке в штабель грузов. 
        Техническое обслуживание и текущий ремонт погрузчика и всех его механизмов. Определение неисправностей в 
        работе погрузчика, его механизмов и их устранение. Установка и замена съемных грузозахватных приспособлений и 
        механизмов. Участие в проведении планово-предупредительного ремонта погрузчика и грузозахватных механизмов и 
        приспособлений. Заряд аккумуляторов.`
    },
    {
        id: 2,
        price: `От 5000р`,
        title: `Водитель погрузчика 4 разряда -`,
        text: ` Управление тракторными погрузчиками, вагонопогрузчиками, вагоноразгрузчиками 
        и всеми специальными грузозахватными механизмами и приспособлениями при погрузке, выгрузке, 
        перемещении и укладке грузов в штабель и отвал. Техническое обслуживание погрузчика и текущий ремонт 
        всех его механизмов. Определение неисправностей в работе погрузчика. Установка и замена съемных 
        грузозахватных приспособлений и механизмов. Участие в проведении планово-предупредительного ремонта 
        погрузчика, грузозахватных механизмов и приспособлений.`
    },
    {
        id: 3,
        price: `От 3500р`,
        title: `Стропальщик 4 разряда -`,
        text: ` Строповка и увязка грузов средней сложности, лесных (длиной свыше 3 до 6 м),
        изделий, деталей и узлов с установкой их на станок, подмостей и других монтажных приспособлений и механизмов,
        а также аналогичных грузов массой свыше 25 т для их подъема, перемещения и укладки.`
    },
    {
        id: 4,
        price: `От 3000р`,
        title: `Рабочий люльки -`,
        text: ` Подъёмники и вышки являются одним из ведущих звеньев при эксплуатации подъёмных сооружений,
        обеспечивающих комплексную механизацию строительства, строительно-ремонтных, 
        электромонтажных, вентиляционных и других видов работ, высокие темпы и 
        индустриальные методы производства`
    },
    {
        id: 5,
        price: `От 14 000р`,
        title: `Ежегодные занятия с водителями автомобилей -`,
        text: ` Профессиональное мастерство водителя определяется как набор качеств,
        обеспечивающих уровень мастерства безопасного, экономичного и комфортного
        управления автомобилем, его предлагается рассматривать как совокупность профессионального
        интеллекта водителя и технических навыков управления автомобилем. При этом профессиональным
        интеллектом называют способность водителя предупреждать возникновение опасных ситуаций в дорожном движении.
        Технические навыки определяют мастерство выхода из опасных критических ситуаций.`
    },
    {
        id: 6,
        price: `От 2100р`,
        title: `Проектирование объектов нефтегазодобычи - `,
        text: ` Разработку проектной документации на строительство, расширение, реконструкцию,
        техническое перевооружение, консервацию и ликвидацию опасного производственного объекта осуществляют проектные организации,
        руководители и специалисты которых прошли обучение и аттестованы по промышленной безопасности в установленном порядке.`
    },
    {
        id: 7,
        price: `От 4500р`,
        title: `Проектирование объектов нефтегазодобычи - `,
        text: ` Разработку проектной документации на строительство, расширение, реконструкцию,
        техническое перевооружение, консервацию и ликвидацию опасного производственного объекта осуществляют проектные организации,
        руководители и специалисты которых прошли обучение и аттестованы по промышленной безопасности в установленном порядке.`
    },
    {
        id: 8,
        price: `От 4500р`,
        title: `Проектирование объектов нефтегазодобычи - `,
        text: ` Разработку проектной документации на строительство, расширение, реконструкцию,
        техническое перевооружение, консервацию и ликвидацию опасного производственного объекта осуществляют проектные организации,
        руководители и специалисты которых прошли обучение и аттестованы по промышленной безопасности в установленном порядке.`
    },
    {
        id: 9,
        price: `От 6000р`,
        title: `Стропальщик 5 разряда -`,
        text: ` Строповка и увязка грузов средней сложности, лесных (длиной свыше 3 до 6 м),
        изделий, деталей и узлов с установкой их на станок, подмостей и других монтажных приспособлений и механизмов,
        а также аналогичных грузов массой свыше 25 т для их подъема, перемещения и укладки.`
    }
]

export default function AboutProfessions() {

    const [content, setContent] = useState(0);

    const handleClick = (id) => {
        setContent(id);
    }

    return (
        <div className={styles.wrapper}>

            <ScrollableAnchor id={'prices'}>
                <h1>Обучение</h1>
            </ScrollableAnchor>
            <p className={styles.description}>Обучение проходит в очной и заочной формах. Проводятся вебинары,
                дистационное обучение, а так же обучение на территории клиента.</p>

            <div className={styles.flexContainer}>
                <div className={styles.contentWrapper}>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(0)}>
                        <img className={styles.icon} src='images/deliverer.svg' alt=''/>
                        Водитель погрузчика 2 разряда
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(1)}>
                        <img className={styles.icon} src='images/deliverer.svg' alt=''/>
                        Водитель погрузчика 3 разряда
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(2)}>
                        <img className={styles.icon} src='images/deliverer.svg' alt=''/>
                        Водитель погрузчика 4 разряда
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(3)}>
                        <img className={styles.icon} src='images/builder.svg' alt=''/>
                        Стропальщик 4 разряда
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(9)}>
                        <img className={styles.icon} src='images/builder.svg' alt=''/>
                        Стропальщик 5 разряда
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(4)}>
                        <img className={styles.icon} src='images/worker.svg' alt=''/>
                        Рабочий люльки
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(5)}>
                        <img className={styles.icon} src='images/driver.svg' alt=''/>
                        Ежегодные занятия с водителями автомобилей
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(6)}>
                        <img className={styles.icon} src='images/accountant.svg' alt=''/>
                        Проектирование объектов нефтегазодобычи
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(7)}>
                        <img className={styles.icon} src='images/accountant.svg' alt=''/>
                        Эксплуатация химически-опасных производственных объектов
                    </Button>
                    <Button classes={{root: styles.content}} onClick={() => handleClick(8)}>
                        <img className={styles.icon} src='images/accountant.svg' alt=''/>
                        Монтаж и наладка подъёмных сооружений
                    </Button>
                    <ReadingCourses/> {/* вывод курсов */}
                </div>

                <div className={styles.about}>
                    <h3>{contents.find(item => item.id === content).title}</h3>
                    <p>{contents.find(item => item.id === content).text}</p>
                    <div className={styles.profession}>
                        <img src='images/money.svg' alt='money'/>
                        <div>
                            {/* <p className={styles.professionTitle}>Охрана труда и промышленная безопасность</p> */}
                            <p className={styles.professionPrice}>
                                {contents.find(item => item.id === content).price}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
