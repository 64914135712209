import React, {useEffect, useState} from 'react';


import { 
    Radio, 
    RadioGroup,
    FormControlLabel, 
} from '@material-ui/core';

const RadioComponent = ({ question, setVarianst, variants }) => {

    const [value, setValue] = useState({
        id: question._id,
        answers: question.answer_options[0]._id,
    });

    useEffect(() => {
        const newVariants = [...variants].filter(obj => obj.id !== question._id);
        setVarianst([...newVariants, value])
    }, [value]);

    useEffect(() => {
        const prevAnswer = variants.find(obj => obj.id === question._id);
        if(prevAnswer){
            setValue({
                id: prevAnswer.id,
                answers: prevAnswer.answers,
            });
        }
        else {
            setValue({
                id: question._id,
                answers: question.answer_options[0]._id,
            });
        }
    }, [question])

    const handleChange = (e) => {
        setValue({...value, answers: e.target.value})
    };

    return (
        <RadioGroup 
            onChange={handleChange}
            value={value.answers}
        >
            {question.answer_options.map(answer => (
                <FormControlLabel
                    key={answer._id} 
                    value="best" 
                    control={<Radio color="primary" value={answer.name}/>} 
                    label={answer.name} />
            ))}
        </RadioGroup>
    );
};

export default RadioComponent;