import React, {useState} from 'react';

import useInterval from '../hooks/useInterval';

const RemainingTime = ({time}) => {
    const [delay, setDelay] = useState(1000);
    const [timer, setTimer] = useState(time);

    useInterval(() => {
        if(timer >= 0) setTimer(timer - 1000);
        else {
            setTimer(0);
            setDelay(null);
        };
    }, delay);

    const remainingTime = new Date(timer)
    const hour = remainingTime.getUTCHours();
    const minutes = remainingTime.getMinutes() < 10 ? `0${remainingTime.getMinutes()}` : `${remainingTime.getMinutes()}`;
    const seconds = remainingTime.getSeconds() < 10 ? `0${remainingTime.getSeconds()}` : `${remainingTime.getSeconds()}`;

    return (
        <>
            {hour}:
            {minutes}:
            {seconds}
        </>
    )
}

export default RemainingTime;