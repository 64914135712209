/* eslint-disable no-const-assign */
import {useState, useContext} from 'react';
import { useHttp } from './useHttp';
import config from '../config';

import { SUCCESS } from '../constants';
import { AuthContext } from '../state/context/authContext';

export const useUserChange = () => {
    const authContext = useContext(AuthContext);
    const { dispatch } = authContext;

    const { request } = useHttp();
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);

    const handleSubmit = async (data) => {

        const reqData ={
            phone: data.phone || '',
            first_name: data.first_name || '',
            third_name: data.third_name || '',
            last_name: data.last_name || '',
        }

        if(data.password) reqData.password = data.password;

        const requestData = await request(`${config.apiUrl}/user-account`, 'PUT', reqData);
        if(requestData.status === 200) {
            setSuccess(true)
            dispatch ( { type: SUCCESS, data: requestData.data } );
        }
        else setError('Error: ', requestData.status);
    }

    return {
        setSuccess,
        setError,
        isError,
        isSuccess,
        handleSubmit,
    }
}