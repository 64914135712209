export const INITIAL = 'INITIAL';
export const PROCESS = 'PROCESS';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const SUCCESS_REGISTER = 'SUCCESS_REGISTER';
export const SUCCESS_GET_DATA = 'SUCCESS_GET_DATA';
export const RESET = 'RESET';
export const GET_TEST = 'GET_TEST';
export const EMLPOYEES_ERROR = 'EMLPOYEES_ERROR';
export const EMLPOYEES_SUCCES = 'EMLPOYEES_SUCCES';