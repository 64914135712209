import React, {useContext,useState} from 'react';

import styles from '../styles/AccountHeader.module.css';

import { LOGOUT } from '../constants';
import { AuthContext } from '../state/context/authContext';
import { Button, DialogTitle, Dialog } from '@material-ui/core';

import config from '../config';

export default function AccountHeader() {
    const authContext = useContext(AuthContext);
    const { dispatch, authState } = authContext;
    const { entity, last_name, first_name, company_name } = authState?.userData?.user_settings;

    const [isError, setError] = useState(false);

    const handleLogOut = async () => {
        const result = await fetch(`${config.apiUrl}/logout`, {
            method: 'POST',
            credentials: "include"
        });
        if(result.ok) dispatch({type:LOGOUT})
        else setError(true);
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.name}>{entity ? company_name : `${last_name} ${first_name}`}</div>
                <div>
                        <Button
                            type='button'
                            classes={{root:styles.logoutBtn}}
                            onClick={handleLogOut}
                        >
                            Выйти
                        </Button>
                </div>
            </div>

            <Dialog
                open={isError}
                onClose={() => setError(false)}
            >
                <DialogTitle>
                    Произошла непредвиденная ошибка. Попробуйте ещё раз.
                </DialogTitle>
            </Dialog>
        </>
    )
}
