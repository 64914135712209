import React from "react";
import api from './../../config/index'
import style from "./style.module.css"
import "./style.css"

class ReadingCourses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(api.apiCourses)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result //drinks - это имя json массива
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    showPopup() {
        document.getElementById("popup").classList.add('open');
    }

    hidePopup() {
        document.getElementById("popup").classList.remove('open');
    }

    render() {
        const {error, isLoaded, items} = this.state;
        if (error) {
            return <div>Ошибка: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Загрузка...</div>;
        } else {
            return (
                <div>
                    <button className={style.btn} onClick={this.showPopup}>Все курсы</button>
                    <div className={style.popupItems} id='popup'>
                        <div className={style.mainPopup}>
                            <div className={style.titlePopup}>Весь список курсов</div>
                            <div>
                                <ul className={style.ul}>
                                    <div className={style.container}>
                                        {items.map(item => (
                                            <li className={style.li}>
                                                <div key={item.id}>
                                                    <div><span className={style.title}>Название:</span> {item.specialty}
                                                    </div>
                                                    <br/>
                                                    {/* strDrink - свойство объекта */}
                                                    <div><span
                                                        className={style.title}>Описание:</span> {item.description}
                                                    </div>
                                                    <br/>
                                                    <div><span className={style.title}>Цена:</span> {item.price} рублей
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </div>
                                    <span className={style.close} onClick={this.hidePopup}/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

}

export default ReadingCourses;