const messages = {
    sucessLogin: (msg) => msg || 'Успешная авторизация',
    failedLogin: (msg) => msg || 'Не верный логин или пароль!',
    sucessRegistration: (msg) => msg || 'Успешная регистрация',
    failedRegistration: (msg) => msg || 'Данный пользователь уже зарегестрирован',
    inProcess: (msg) => msg || 'Авторизируемся...',
    successRegister: (msg) => msg || 'Success registration. Please check e-mail',
    successGetData: (msg) => msg || "Успешно получили данные",
    failedGetData: (msg) => msg || "Ошибка при получении данных"
  };
  
  export const {
    sucessLogin,
    failedLogin,
    sucessRegistration,
    failedRegistration,
    inProcess,
    successRegister,
    successGetData,
    failedGetData
  } = messages;
  