import React from 'react'
import styles from '../../styles/MainComponents/Training.module.css';

export default function Training() {
    return (
        <div className={styles.wrapper}>
            <h1>Учебный центр</h1>
            <div>
                <p>Учебный центр - дополнительное профессиональное образование, переподготовка, повышение квалификации работников</p>
                <p>Работаем по Тюмени и Тюменской области</p>
            </div>
            <p>Свяжитесь с нами:<br/> <a href='tel:+79224702259'>+7 (922) 470-22-59</a></p>
        </div>
    )
}
