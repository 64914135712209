import React, { useContext } from 'react';
import { useCourseData } from '../../hooks/useCourseData';
import { AuthContext } from '../../state/context/authContext';

import {Template} from '../layouts/Template';

import CourseCard from '../CourseСard';

import styles from '../../styles/pages/coursesPage.module.css';

export default function AllCourses() {
    const authContext = useContext(AuthContext);
    const {authState} = authContext;
    const userSettings = authState?.userData?.user_settings;

    const { isCourses, loading} = useCourseData();

    return (
        <>
            <Template>
                <div className={styles.coursesWrapper}>
                    {loading ? 'Загрузка...' : isCourses.length 
                        && isCourses.map(course => (
                        <CourseCard 
                            key={course._id}
                            bought={ userSettings?.courses?.includes(course._id) || userSettings?.company_courses?.find( id => course._id  === id ) } 
                            data={course}
                        />
                        ))
                    }
                </div>
            </Template>
        </>
    )
}
