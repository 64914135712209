import React, {useState, useContext} from 'react';
import {Redirect} from 'react-router';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';

import NavBar from '../../NavBar';

import styles from '../../../styles/login/register.module.css';

import {useAuth} from '../../../hooks/useAuth';

import {AuthContext} from '../../../state/context/authContext';


let ViewShowPass = () => {
    let inputId = document.getElementById('passwordInput');
    let imgId = document.getElementById('passwordImg');

    if (inputId.getAttribute('type') === 'password') {
        inputId.setAttribute('type', 'text');
        imgId.src = './no-view.svg';
        imgId.title = "Скрыть пароль";
    } else {
        inputId.setAttribute('type', 'password');
        imgId.src = './view.svg';
        imgId.title = "Показать пароль";
    }
    return false;
}



export default function Register() {
    const authContext = useContext(AuthContext);
    const {authState} = authContext;

    const initialValues = {
        email: '',
        password: '',
        privacyPolicy: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email('Вы ввели некорректную почту!')
            .required('Обязательное поле'),

        password: Yup
            .string()
            .min(8, "Пароль должен состоять из не менее 8 символов")
            .max(20, "Пароль должен состоять из не более 20 символов")
            .required('Обязательное поле'),

        privacyPolicy: Yup
            .string()
            .matches(/true/, {message: 'Подтвердите согласие с политикой конфеденциальности'})
            .required('Подтвердите согласие с политикой конфеденциальности'),
    });

    const [popap, setPopap] = useState(false);

    const {registerHandler, isAuthError} = useAuth();

    const [redirect, setRedirect] = useState(false);


    const handleSubmit = async data => {
        await registerHandler(data);
        setPopap(true);
    };


    if (redirect) {
        return <Redirect to='/login'/>;
    }
    return (
        <>
            <NavBar/>
            <div className={styles.register}>

                <Formik
                    initialValues={initialValues}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validationSchema={validationSchema}
                    onSubmit={(v, api) => {

                        handleSubmit(v)
                        api.setSubmitting(false);
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className={styles.form}>
                                <div className={styles.titleContainer}>
                                    <h1 className={styles.title}>Регистрация</h1>
                                </div>
                                <Field
                                    name="email"
                                    className={styles.input}
                                    placeholder="Электронная почта"
                                />
                                {props.errors.email && props.touched.email ?
                                    <div className={styles.validation}>{props.errors.email}</div> : null}

                                <div className={styles.passContainer}>
                                    <Field
                                        className={styles.input}
                                        name="password"
                                        type="password"
                                        placeholder="Пароль"
                                        id="passwordInput"
                                    />
                                    <img src="./view.svg" title="Показать пароль" className={styles.viewPas}
                                         id="passwordImg" onClick={ViewShowPass}/>
                                </div>
                                {props.errors.password && props.touched.password ?
                                    <div className={styles.validation}>{props.errors.password}</div> : null}

                                <div className={styles.persData}>

                                    <Field
                                        className={styles.check}
                                        name="privacyPolicy"
                                        type="checkbox"
                                    />


                                    <p>
                                        Я согласен с политикой
                                        <br/> конфеденциальности
                                    </p>

                                    {props.errors.privacyPolicy && props.touched.privacyPolicy ?
                                        <div className={styles.validation}>{props.errors.privacyPolicy}</div> : null}
                                </div>


                                <Button type="submit" disabled={props.isSubmitting} classes={{root: styles.send}}>
                                    {props.isSubmitting ? "Регистрируем..." : "Регистрация"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                <Dialog
                    onClose={() => {
                        setPopap(false)
                        if (!isAuthError) setTimeout(() => setRedirect(true), 300);
                    }}
                    open={popap}
                >
                    <DialogTitle>
                        {authState.message}
                    </DialogTitle>
                </Dialog>

            </div>
        </>
    );
}
