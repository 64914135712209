import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {

    useEffect(() => {
      if(window.Chatra) window.Chatra('restart');
    }, [])

    return (
      <Route
        exact={rest.exact}
        computedMatch={rest.computedMatch}
        path={rest.path}
        url={rest.url}
        render={() => <Component />}
      />
    );
};
export default PublicRoute;