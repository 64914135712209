import React, { useReducer, createContext, useEffect } from 'react';
import { authReducer } from '../reducers/authReducer';
import { LOGOUT, SUCCESS,EMLPOYEES_SUCCES, EMLPOYEES_ERROR } from '../../constants';
import {useHttp} from '../../hooks/useHttp';

import {cookiesSet} from '../cookies';

import config from '../../config';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const {request} = useHttp();
    const [authState, dispatch] = useReducer(authReducer, {
        isAuth: false,
        userData: {},
        message: false,
        loading: true,
        emlpoyees: [],
    })

    useEffect(() => {
        (async function(){
          const res = await request(`${config.apiUrl}/user-account`, "POST");
          const {data, status} = res;

          if(status === 200) {

            dispatch({type: SUCCESS, data})

            if(data?.user_settings?.entity) {
              const result = await request(`${config.apiUrl}/employees`, 'POST');
              const { data, ok } = result;
              dispatch(ok ? {type: EMLPOYEES_SUCCES, data: data.data} : {type: EMLPOYEES_ERROR})
            }

          }
          else dispatch( {type : LOGOUT })
        })()
    }, []);

    // if (process.browser) {
    //     const session = localStorage.getItem('isAuth');
    //     if (JSON.parse(session)) authState.isAuth = true;
    // }
    
    return (
        <AuthContext.Provider
          value={{
            authState,
            dispatch,
          }}
        >
          {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;