import React, {useEffect, useState} from 'react';

import TextField from '@material-ui/core/TextField';

const RadioComponent = ({ question, setVarianst, variants, type }) => {

    const [value, setValue] = useState({
        id: question._id,
        answers: '',
    });

    useEffect(() => {
        const newVariants = [...variants].filter(obj => obj.id !== question._id);
        setVarianst([...newVariants, value])
    }, [value]);

    useEffect(() => {
        const prevAnswer = variants.find(obj => obj.id === question._id);
        if(prevAnswer){
            setValue({
                id: prevAnswer.id,
                answers: prevAnswer.answers,
            });
        }
        else {
            setValue({
                id: question._id,
                answers: '',
            });
        }
    }, [question])

    const handleChange = (e) => {
        setValue({...value, answers: e.target.value})
    };

    return (
        <TextField onChange={handleChange} value={value.answers} type={type}/>
    );
};

export default RadioComponent;