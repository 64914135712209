/* eslint-disable no-const-assign */
import React, {useEffect, useState} from 'react';
import { useHttp } from './useHttp';
import config from '../config';

export const useCourseData = () => {
    const { request, loading } = useHttp();
    const [isCourses, setCourses] = useState([]);
    const [isError, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const requestData = await request(`${config.apiUrl}/courses`);
                if(requestData.status === 200 || requestData.ok === true) {
                    setCourses (requestData.data);
                } setError('Error: ', requestData.status);
            } catch (error) {
                setError(`Error: `, error);
            }
        })()
    }, [])

    return {
        loading,
        isCourses,
        isError,
        setCourses,
    }
}