import AccountHeader from '../AccountHeader';
import AccountNavBar from '../AccountNavBar';

import styles from '../../styles/Template.module.css'

const defaultStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    top: '51px',
    gridColumn: '2/3',
}

const fullScreen = {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
}

export const Template = (props) => {


    if(props.fullScreen) {
        return (
            <div className={styles.wrapper}>
                <div style={fullScreen}>
                    {props.children}
                </div>
            </div>
        )
    } 
    return (
        <div className={styles.wrapper}>
            <AccountHeader/>
            <AccountNavBar/>
            <div style={{display: 'flex', position: 'relative', top:'51px', gridColumn: '2/3'}}>
                {props.children}
            </div>
        </div>
    )
}