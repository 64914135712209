import React, { useContext } from 'react';
import { AuthContext } from '../../state/context/authContext';

import {Template} from '../layouts/Template';

import styles from '../../styles/pages/userCertificates.module.css';
import { Button } from '@material-ui/core';

export default function UserCertificates() {
    const authContext = useContext(AuthContext);
    const {authState} = authContext;
    const userSettings = authState?.userData?.user_settings;

    return (
        <>
            <Template>
                <div className={styles.wrapper}>
                    
                {
                    userSettings.certificates.length ? null: 
                        <div className={styles.noneCertificates}>Вы получите сертификат, за прохождение любого курса.
                            <br/> Пока у вас нет ни одного сертификата.
                        </div>
                }
                    
                {userSettings.certificates.length ? (
                    userSettings.certificates.map(certificat => (
                        <div className={styles.certificatesWrapper}>

                            <div className={styles.container} style={{ backgroundImage:`url(${certificat?.image?.url || 'images/unnamed.jpg'})`}}>

                                <div className={styles.titleWrapper}>
                                    <p>Сертификат за курс:</p>
                                    <p>{certificat.name}</p>
                                </div>

                            </div>

                            <a
                                target='_blank'
                                className={styles.link}
                                href={certificat?.image?.url}
                                download={certificat.specialty + '.jpg'}
                                title={certificat.specialty}
                                style={{textDecoration:'none'}} 
                                rel="noreferrer"
                            >
                                <Button
                                    classes={{ root: styles.btn }}
                                    type='button'
                                >
                                    Скачать сертификат
                                </Button> 
                            </a> 
                        </div>
                    ))
                ) : null}
                </div>
            </Template>
        </>
    )
}
