import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import { AuthContext } from '../state/context/authContext';

import config from '../config/index';

import styles from '../styles/AccountNavBar.module.css';


export default function AccountNavBar() {

    const authContext = useContext(AuthContext);
    const { authState } = authContext;
    const { entity } = authState?.userData?.user_settings;

    const [contract, setContrart] = useState();

    useEffect(() => {
        (async function (){
            
            try{
                const result = await fetch(`${config.apiUrl}/contract_file`);
                setContrart(result.url);
            }catch(err){
                console.error(err);
            }
        })()

    }, []);

    return (
        <div className={styles.wrapper}>
            <Link to='/courses'>
                <p>Курсы</p>
            </Link>

            <Link to='/courses-of-user'>
                <p>Мои курсы</p>
            </Link>

            <Link to='/certificates-of-user'>
                <p>Мои сертификаты</p>
            </Link>

            {entity ? 
            <Link to='/company-emploeies'>
                <p>Мои сотрудники</p>
            </Link> : null}

            <Link to='/settings'>
                <p>Настройки пользователя</p>
            </Link>

            {entity ? 
                <a className={styles.contract} target='_blank' href={contract} rel="noreferrer">
                    <p style={{marginBottom: '4px'}}>Образец договора</p>
                </a> : null}
        </div>
    )
}
