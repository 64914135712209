import { SUCCESS, ERROR, LOGOUT, SUCCESS_REGISTER,GET_TEST, EMLPOYEES_SUCCES,EMLPOYEES_ERROR } from '../../constants';
import { sucessRegistration, failedLogin } from '../../utils/messages';

export const authReducer = (currentState, { message, type, data, userSettings }) => {
    switch (type) {
        case EMLPOYEES_ERROR:
          return { 
            ...currentState,
            emlpoyees: [],
          };
        case EMLPOYEES_SUCCES:
          return { 
            ...currentState,
            emlpoyees: data,
          };
        case SUCCESS_REGISTER:
          return { 
            ...currentState,
            isAuth: false,
            message: sucessRegistration(),
            loading: false,
          };
        case SUCCESS:
          return {
            ...currentState,
            isAuth: true,
            userData: data,
            loading: false,
            message: '',
          };
        case ERROR:
          return { 
            ...currentState,
            isAuth: false,
            message: failedLogin(message),
            loading: false,
            userData: {},
          };
        case LOGOUT:
          return {
            ...currentState,
            isAuth: false,
            userData: {},
            loading: false,
          };
          case GET_TEST:
            const testData = {...currentState};
            testData.userData.user_settings = userSettings;
            return testData;
        default:
          throw new Error('Error in authReducer');
    }
}