import React from 'react';

import styles from '../styles/pages/result.module.css';

import RemainingTime from './RemainingTime';


const EmployeeResult = ({ test }) => {
    const t = new Date(+(new Date(test?.date_closed)) + +(new Date( (test?.hours_to_try_again || 0) * 60 * 60 * 1000 )));

    const time = new Date(test?.date_closed);

    const year =    time.getFullYear();
    const month =   (time.getMonth() + 1) < 10 ? `0${time.getMonth() + 1}` : `${time.getMonth() + 1}`;
    const day =     time.getDate();
    const hour =    time.getHours() < 10 ? `0${time.getHours()}` : `${time.getHours()}`;
    const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : `${time.getMinutes()}`;

    return (
        <div style={{margin:'auto'}} className={styles.wrapper}>            
        <p className={styles.totalQuestions}>
            Всего вопросов в тесте: <span>{test?.questions.length}</span>
        </p>

        <div className={styles.answers}>Верно пройденных вопросов: 
            <div className={styles.goodAnswers}>{test?.correct_questions}</div>
        </div>

        <div className={styles.answers}>Дано верных ответов: 
            <div className={styles.goodAnswers}>{test?.correct_answers}</div>
        </div>
        
        <div className={styles.answers}>Не верно пройденных вопросов: 
            <div className={styles.badAnswers}>{test?.failure_answers}</div>
        </div>

        {/* correct_answers: {test.correct_answers} <br/>
        failure_answers: {test.failure_answers}<br/>
        total_correct_answers: {test.total_correct_answers}<br/>
        total_correct_questions: {test.total_correct_questions}<br/> */}

        {test?.passed ? (
            <div className={styles.goodResultWrapper}>
                <h3>Тест успешно пройден!</h3>
            </div>
            ) : (
            <div className={styles.badResultWrapper}>
                Тест не сдан
            </div>
        )}

        {test?.passed ? (
                null
            ) : (
            <div className={styles.attempt}>
                <div className={styles.titleAttempt}>Следующая попытка прохождения станет доступна:</div>
                <div className={styles.date}>{`${year}.${month}.${day} ${hour}:${minutes}`.toLocaleUpperCase()}</div>
                <div className={styles.timer}>Доступ откроется через: <span><RemainingTime time={t - new Date()}/></span> </div>
            </div>
        )}
    </div>
    )
}

export default EmployeeResult;