import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../../styles/MainComponents/WhereToBegin.module.css';


export default function WhereToBegin() {

    return (
        <div className={styles.wrapper}>
            <ScrollableAnchor id={'begin'}>
                <h1>С чего начать?</h1>
            </ScrollableAnchor>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <img src='images/phone.svg' alt=''/>
                    <p>Оставьте заявку на сайте или позвоните по телефону</p>
                </div>
                <div className={styles.content}>
                    <img src='images/operator.svg' alt=''/>
                    <p>Консультация со специалистом. Обсуждаем индивидуальные условия работы, сроки и стоимость.</p>
                </div>
                <div className={styles.content}>
                    <img src='images/docs.svg' alt=''/>
                    <p>Заключаем договор. Для этого вам понадобится: паспорт, фотография 3*4, документ об образовании, в некоторых случаях трудовая книжка</p>
                </div>
                <div className={styles.content}>
                    <img src='images/hat.svg' alt=''/>
                    <p>Мы предоставляем Вам методические материалы по электронной почте или на флешку</p>
                </div>
                <div className={styles.content}>
                    <img src='images/license.svg' alt=''/>
                    <p>В течение 2-3 рабочих дней вы получите документы в офисе или доставкой по почте.</p>
                </div>
            </div>
            
        </div>
    )
}
