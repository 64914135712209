import {useContext ,useState, useEffect} from 'react';
import { AuthContext } from '../state/context/authContext';
import { SUCCESS,GET_TEST } from '../constants';
import {getObjectById} from '../utils/getObjectById';

import test from '../constants/test.json';


import {useHttp} from './useHttp';

import config from '../config/index';



export default function useTestData(id) {
    const { request, loading, error } = useHttp();
    const authContext = useContext(AuthContext);
    const { dispatch, authState } = authContext;

    const [isTest, setTest] = useState(null);
    const [time, setTime] = useState(null)

    useEffect(() => {
        (async function(){
            const result = await request(`${config.apiUrl}/tests/${id}/start`, 'POST');
       
            const {data , ok} = result;
            if(ok){
                // setTest(authState.userData.user_settings.current_tests[0]);
                dispatch({type: GET_TEST, userSettings: data})
            }
            else{           
                // setTest(test.current_tests[0]);
                // const time = new Date(test.current_tests[0].date_expiration) - new Date(test.current_tests[0].date_started);
                // setTime(time);
            }
        })()
    }, []);

    return {loading, error, isTest, time ,setTime}
}
